<template>
  <div>
    <div class="myFarm weather" v-if="farmName">
      <section class="farmInfo">
        <article>
          <h3>{{ farmName }}</h3>
          <p>
            <span class="material-icons"> location_on </span>
            {{ address }}
          </p>
          <div class="weatherWrap box">
            <div class="loadingBox" v-if="weatherErr">
              <p>
                날씨 정보 오류가 발생하였습니다.<br />
                새로고침을 눌러 다시 시작해주세요.
                <button @click="getWeather()" :class="{ loading: loading == true }" :disabled="loading == true">
                  <span class="material-icons"> refresh </span>
                </button>
              </p>
            </div>
            <div v-else-if="loading" class="loadingBox"><img src="@/assets/images/loading.png" /></div>
            <div v-else-if="weather">
              <div class="flexB">
                <div>
                  <span>{{ moment(weather.data[0].baseDate).locale("ko").format("M월 D일(ddd)") }}{{ weather.data[0].baseTime.substr(0, 2) + ":00" }} 기준</span>
                  <button @click="getWeather()" :class="{ loading: loading == true }" :disabled="loading == true">
                    <span class="material-icons"> refresh </span>
                  </button>
                </div>
                <img :src="require(`@/assets/images/${imgBinding(weather.weatherOneDay.SKY, weather.weatherOneDay.PTY)}.png`)" alt="weather" />
              </div>
              <div class="flexB">
                <p>
                  <span>{{ weather.weatherOneDay.TMP }}</span
                  >℃
                </p>
                <p>최고:{{ weather.weatherOneDay.TMX }}℃ 최저:{{ weather.weatherOneDay.TMN }}℃</p>
              </div>
              <div class="weather">
                <div>
                  <p><img src="@/assets/images/wind.png" alt="wind" /> 바람</p>
                  <p>
                    <span>{{ handleVEC(weather.weatherOneDay.VEC) }} {{ weather.weatherOneDay.VVV }}</span
                    >m/s
                    <!-- UUU는 동서바람, VVV는 남북바람인데... 뭘로 바인딩 해야할지 모르겠음 -->
                  </p>
                </div>
                <div>
                  <p><img src="@/assets/images/humidity.png" alt="wind" /> 습도</p>
                  <p>
                    <span>{{ weather.weatherOneDay.REH }}</span
                    >%
                  </p>
                </div>
                <div>
                  <p><img src="@/assets/images/rain.png" alt="wind" /> 강수량</p>
                  <p>
                    <span>{{ weather.weatherOneDay.PCP == "강수없음" ? "-" : weather.weatherOneDay.PCP.split("mm")[0] }}</span
                    >{{ weather.weatherOneDay.PCP == "강수없음" ? "" : "mm" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>

      <section class="radioWrap">
        <el-radio-group v-model="label" @change="handleChartData">
          <el-radio-button label="0">오늘</el-radio-button>
          <el-radio-button label="1">내일</el-radio-button>
          <el-radio-button label="2">모레</el-radio-button>
          <el-radio-button label="3">글피</el-radio-button>
        </el-radio-group>
      </section>

      <section class="chart">
        <article class="box">
          <p class="bold"><img src="@/assets/images/temperature.png" alt="temperature" /> 기온</p>
          <LineChart v-if="TMPseries.length > 0" :chartData="TMPseries" :category="category" />
        </article>
      </section>

      <section class="chart">
        <article class="box">
          <p class="bold"><img src="@/assets/images/rain.png" alt="wind" /> 강수량</p>
          <BarChart v-if="PCPseries.length > 0" :chartData="PCPseries" :category="category" />
        </article>
      </section>
      <section class="chart">
        <article class="box">
          <p class="bold"><img src="@/assets/images/humidity.png" alt="wind" /> 습도</p>
          <AreaChart v-if="REHseries.length > 0" :chartData="REHseries" :category="category" />
        </article>
      </section>
    </div>
    <div v-else><p class="nodata">농장을 등록해 주세요.</p></div>
  </div>
</template>
<script>
import LineChart from "../components/contents/LineChart.vue";
import { mapState } from "vuex";
import { fetchWeather } from "@/api/farm";
import moment from "moment";
import AreaChart from "../components/contents/AreaChart.vue";
import BarChart from "../components/contents/BarChart.vue";

export default {
  components: { LineChart, AreaChart, BarChart },
  data() {
    return {
      moment: moment,
      loading: false,
      value: "1",
      label: "0",
      farmId: "",
      address: "",
      farmName: "",
      nx: "",
      ny: "",
      PCP: "",
      PTY: "",
      REH: "",
      SKY: "",
      TMP: "",
      TMX: "",
      TMN: "",
      VEC: "",
      VVV: "",
      weatherOneDay: [],
      TMPseries: [],
      PCPseries: [],
      REHseries: [],
      category: [],
    };
  },
  computed: {
    ...mapState(["farmList", "weather", "weatherErr"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "날씨정보");
  },
  mounted() {
    if (this.$route.query.id) {
      this.farmId = this.$route.query.id;
      let select = this.farmList.filter((item) => item._id == this.farmId);
      this.address = select[0].address;
      this.farmName = select[0].farmName;
      this.nx = select[0].nx;
      this.ny = select[0].ny;
      this.handleChartData();
    }
  },
  methods: {
    handleChartData() {
      this.category = [];
      this.TMPseries = [];
      this.REHseries = [];
      this.PCPseries = [];
      let today = moment().add(this.label, "d");

      this.weather.data.filter((item) => {
        if (item.category == "TMP" && item.fcstDate == today.format("YYYYMMDD")) {
          this.category.push(item.fcstTime.substr(0, 2));
          this.TMPseries.push(item.fcstValue);
        } else if (item.category == "REH" && item.fcstDate == today.format("YYYYMMDD")) {
          this.REHseries.push(item.fcstValue);
        } else if (item.category == "PCP" && item.fcstDate == today.format("YYYYMMDD")) {
          this.PCPseries.push(item.fcstValue == "강수없음" ? 0 : item.fcstValue);
        }
      });
    },

    getWeather() {
      this.loading = true;
      let data = {
        x: this.nx,
        y: this.ny,
      };
      fetchWeather(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.commit("SET_WEATHER", res.data.data);
          this.handleChartData();
          this.loading = false;
        } else {
          this.loading = false;
          this.openAlert(res.data.message);
        }
      });
    },
    imgBinding(sky, pty) {
      // - 하늘상태(SKY) 코드 : 맑음(1), 구름많음(3), 흐림(4)
      // - 강수형태(PTY) 코드 : (초단기) 없음(0), 비(1), 비/눈(2), 눈(3), 빗방울(5), 빗방울눈날림(6), 눈날림(7)
      //                       (단기) 없음(0), 비(1), 비/눈(2), 눈(3), 소나기(4)
      let imgName;
      if (sky != "0") {
        imgName = "skycode_" + sky;
      } else if (pty != "0") {
        imgName = "raincode_" + pty;
      }
      return imgName;
    },
    handleVEC(num) {
      var result = Math.floor((Number(num) + 22.5 * 0.5) / 22.5);
      let cardinalPoint = "";
      switch (result) {
        case 0:
          cardinalPoint = "북";
          break;
        case 1:
          cardinalPoint = "북북동";
          break;
        case 2:
          cardinalPoint = "북동";
          break;
        case 3:
          cardinalPoint = "동북동";
          break;
        case 4:
          cardinalPoint = "동";
          break;
        case 5:
          cardinalPoint = "동남동";
          break;
        case 6:
          cardinalPoint = "남동";
          break;
        case 7:
          cardinalPoint = "남남동";
          break;
        case 8:
          cardinalPoint = "남";
          break;
        case 9:
          cardinalPoint = "남남서";
          break;
        case 10:
          cardinalPoint = "남서";
          break;
        case 11:
          cardinalPoint = "서남서";
          break;
        case 12:
          cardinalPoint = "서";
          break;
        case 13:
          cardinalPoint = "서북서";
          break;
        case 14:
          cardinalPoint = "북서";
          break;
        case 15:
          cardinalPoint = "북북서";
          break;
      }
      return cardinalPoint;
    },
  },
};
</script>

<!-- 
PCP: "강수없음 (1100)" //1시간 강수량	범주 (1 mm)
POP: "30 (1100)" //강수확률	%
PTY: "0 (1100)" //강수형태
REH: "85 (1100)" //습도 %
SKY: "4 (1100)" //하늘상태	코드값
SNO: "적설없음 (1100)" //1시간 신적설	범주(1 cm)
TMP: "27 (1100)" //1시간 기온	℃
UUU: "2 (1100)" //동서바람성분 m/s
VEC: "221 (1100)" //풍향 deg
VVV: "2.3 (1100)" //남북바람성분 m/s
WAV: "0 (1100)" //파고	M
WSD: "3 (1100)" //풍속 m/s -->
