<template>
  <div id="chart">
    <apexchart ref="chart" type="area" width="100%" height="200px" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["chartData", "category"],

  data() {
    return {
      series: [
        {
          name: "습도",
          data: this.chartData,
        },
      ],
      chartOptions: {
        chart: {
          background: "#fff",
          toolbar: {
            show: false,
          },
        },
        colors: ["#fe8424"],
        stroke: {
          show: true,
          width: 3,
          curve: "smooth",
        },

        xaxis: {
          categories: this.category,

          crosshairs: {
            show: true,
            width: 3,
            position: "back",
            opacity: 1,
          },
        },
        markers: {
          size: 4,
          strokeWidth: 2,
        },
        dataLabels: {
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -5,
          style: {
            colors: ["#333"],
            fontWeight: 400,
          },
          background: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
          tickAmount: 2,
        },

        grid: {
          show: false,
        },
      },
    };
  },

  watch: {
    chartData: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.$refs.chart.updateOptions(
        {
          series: [
            {
              name: "습도",
              data: this.chartData,
            },
          ],
          xaxis: {
            categories: this.category,
          },
        },
        true
      );
    },
  },
};
</script>
